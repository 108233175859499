@font-face {
  font-family: 'Joschmi';
  src: local('Joschmi'), url(./fonts/Joschmi.otf) format('opentype');
}

@font-face {
  font-family: 'Reross';
  src: local('Reross'), url(./fonts/Reross.otf) format('opentype');
}

@font-face {
  font-family: 'CarlMarx';
  src: local('CarlMarx'), url(./fonts/CarlMarx.otf) format('opentype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: CarlMarx;
}
